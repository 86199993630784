import { Button, Divider, Dragger, Input, Modal, Select, TextArea } from "components";
import { useEffect, useState } from "react";
import { Col, Form, Modal as M, Row } from "react-bootstrap";
import styles from "pages/settings/GoodLooks.module.css";
import { activeEnum } from "utils/enums";
import { s3config } from "utils/s3config";
import { useAppSelector, useS3Upload } from "hooks";
import { notification, Spin } from "antd";

interface CategoryFormPropsI {
    okHandler: (object:any) => void;
    visible: boolean;
    closeModal: () => void;
    currentOrder: number;
}

const CategoryFormModal = ({okHandler, visible, closeModal, currentOrder}:CategoryFormPropsI) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [nameEng, setNameEng] = useState("");
    const [descriptionEng, setDescriptionEng] = useState("");
    const [photo, setPhoto] = useState<any>("");
    const [photoBlob, setPhotoBlob] = useState<any>("");
    const [s3Photo, setS3Photo] = useState("");
    const [cover, setCover] = useState<any>("");
    const [coverBlob, setCoverBlob] = useState<any>("");
    const [s3Cover, setS3Cover] = useState("");
    const [status, setStatus] = useState("");
    const [isPhotoLoading, setIsPhotoLoading] = useState(false);
    const [isCoverLoading, setIsCoverLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [order, setOrder] = useState<number>(currentOrder);
    const { uploadingFile } = useS3Upload();
    const { categories, category } = useAppSelector(({ catalogs }) => catalogs);
    const { data } = categories;

    const availableOrders: any = (data.map((d:any)=> { return {value: d.order, label: d.order} } ).sort((a:any,b:any)=> a.value > b.value ? 1 : -1));


    const handleSubmit = (e:any) => {
        e.preventDefault();
        okHandler({
            category: {
                name: {en: nameEng, es: name},
                description: {en: descriptionEng, es: description},
                photo: s3Photo,
                status: status === "active",
                cover: s3Cover,
                order: order
            }
        });
    }

    const handleChange = ({ target}:any) => {
        const {name, value} = target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "nameEng":
                setNameEng(value);
                break;
            case "descriptionEng":
                setDescriptionEng(value);
                break;
            default:
                return null;
        }
    }

    const handleSelect = (value:string) => {
        setStatus(value);
    }


    const handleOrder = (value:number)=> {
        setOrder(Number(value));
    }
    

    const handleClearState = () => {
        setName("");
        setDescription("");
        setPhoto("");
        setPhotoBlob("");
        setS3Photo("");
        setCover("");
        setCoverBlob("");
        setS3Cover("");
        setStatus("");
    }

    const handleHideModal = () => {
        handleClearState();
        closeModal();
    }

    const uploadAwsS3 = async (type:string) => {
        try {
            type === "photo" ? setIsPhotoLoading(true) : setIsCoverLoading(true);
            const { success, location, error } = await uploadingFile(type === "photo" ? photo : cover, 
                {
                    ...s3config,
                    dirName: type === "photo" ? "categories" : "categories/covers"
                });
            if (success) {
                type === "photo" ? setS3Photo(location) : setS3Cover(location);
            } else {
                type === "photo" ? setS3Photo("") : setS3Cover("");
                notification.error({
                    message: "Error",
                    description: error || "Error en S3"
                });
            }
            type === "photo" ? setIsPhotoLoading(false) : setIsCoverLoading(false);
        } catch (error:any) {
            type === "photo" ? setIsPhotoLoading(false) : setIsCoverLoading(false);
            type === "photo" ? setS3Photo("") : setS3Cover("");
            notification.error({
                message: "Error",
                description: error.message
            });
        }
    }

    const formValidation = () => {
        if (name && nameEng && description && descriptionEng && status && s3Photo && s3Cover)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }

    const fileValidation = (file:any, type: string) => {
        if (file.size < 500000)
            return true;
        notification.error({
            message: "Error",
            description: "La imagen excede el támaño permitido"
        });
        if (type === "photo"){
            setPhoto("");
            setPhotoBlob("");
        } else {
            setCover("");
            setCoverBlob("");
        }
        return false
    }

    useEffect(() => {
        formValidation();
    }, [name, nameEng, description, descriptionEng, status, s3Photo, s3Cover])

    useEffect(() => {
        if (photo)
            uploadAwsS3("photo");
    }, [photo])

    useEffect(() => {
        if (cover)
            uploadAwsS3("cover");
    }, [cover])

    useEffect(()=>{

    },[order]);
    
    useEffect(() => {
        if (Object.keys(category).length > 0) {
            setName(category.name.es);
            setNameEng(category.name.en);
            setDescription(category.description.es);
            setDescriptionEng(category.description.en);
            setStatus(category.status ? "active" : "inactive");
            setPhotoBlob(category.photo);
            setS3Photo(category.photo);
            setCoverBlob(category.cover);
            setS3Cover(category.cover);
        }
    }, [])

    return (
        <Modal show={visible} centered size="lg"
            onHide={handleHideModal} backdrop="static" keyboard={false}
        >
            <M.Header closeButton>
                <h6 className="m-0">{`${Object.keys(category).length > 0 ? "Editar" : "Agregar"} Categoría`}</h6>
            </M.Header>
            <M.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <h6>{"Nombre de Categoría"}</h6>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input
                                id="name"
                                name="name"
                                label="Español"
                                classNameContainer="mb-2"
                                value={name}
                                onChange={handleChange}
                                maxLength={50}
                                showCount
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input
                                id="nameEng"
                                name="nameEng"
                                label="Inglés"
                                classNameContainer="mb-2"
                                value={nameEng}
                                onChange={handleChange}
                                maxLength={50}
                                showCount
                            />
                        </Col>
                        <h6>{"Descripción de Categoría"}</h6>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextArea
                                id="description"
                                name="description"
                                label="Español"
                                classNameContainer="mb-2"
                                value={description}
                                onChange={handleChange}
                                maxLength={100}
                                showCount
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextArea
                                id="descriptionEng"
                                name="descriptionEng"
                                label="Inglés"
                                classNameContainer="mb-2"
                                value={descriptionEng}
                                onChange={handleChange}
                                maxLength={100}
                                showCount
                            />
                        </Col>
                        <Divider style={{ margin: "8px 0"}}/>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Select 
                                label="Estatus"
                                classNameContainer="mb-2"
                                classNameLabel="w-100"
                                style={{ width: "100%" }}
                                options={activeEnum}
                                defaultValue={status}
                                value={status}
                                onChange={handleSelect}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Select 
                                label="Orden"
                                classNameContainer="mb-2"
                                classNameLabel="w-100"
                                style={{ width: "100%" }}
                                options={availableOrders.map((item:any) => ({ value: item.value, label: item.label }))}
                                defaultValue={order}
                                value={order}
                                onChange={handleOrder}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}/>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            {
                                currentOrder !== order &&
                                <p style={{color: "rgba(0, 0, 0, 0.45)", float: 'right'}}>{`Este Ajuste Intercambiara los ordenes: ${currentOrder} y ${order}`}</p>
                            }
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}/>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Spin spinning={isPhotoLoading}>
                                <Dragger
                                    label={"Foto"}
                                    classNameContainer="mb-2"
                                    accept="image/*"
                                    beforeUpload={(file) => {
                                        if (fileValidation(file, "photo")) {
                                            setPhoto(file);
                                            setPhotoBlob(URL.createObjectURL(file));
                                        }
                                        return false;
                                    }}
                                    showUploadList={false}
                                    image={photoBlob}
                                    text={"Tamaño máximo permitido 500KB"}
                                    classNameImage={styles["category-image"]}
                                />
                            </Spin>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Spin spinning={isCoverLoading}>
                                <Dragger
                                    label={"Cover"}
                                    classNameContainer="mb-2"
                                    accept="image/*"
                                    beforeUpload={(file) => {
                                        if (fileValidation(file, "cover")) {
                                            setCover(file);
                                            setCoverBlob(URL.createObjectURL(file));
                                        }
                                        return false;
                                    }}
                                    showUploadList={false}
                                    image={coverBlob}
                                    text={"Tamaño máximo permitido 500KB"}
                                    classNameImage={styles["cover-image"]}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "16px 0"}} />
                    <Row>
                        <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                            <Button size="sm" className="me-3 pry-outline-btn" onClick={handleHideModal}>
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" className="primary-btn" type="submit" disabled={isDisabled}>
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default CategoryFormModal;